import {JsonConverter, JsonCustomConvert, JsonObject, JsonProperty} from "json2typescript"
import DateTimeConverter from "@/services/converters/DateTimeConverter"
import PotentialClient from "@/models/PotentialClient"
import {NotifyType} from "@/services/EventService"
import {DateTime} from "luxon";
import EventStatusComponent from "@/components/EventStatusComponent.vue";

export enum EventStatus {
    WAITING = "WAITING",
    IN_PROGRESS = "IN_PROGRESS",
    FINISHED = "FINISHED",
    POSTPONED = "POSTPONED",
    CANCELLED = "CANCELLED"
}


@JsonConverter
class NotifyTypeConverter implements JsonCustomConvert<NotifyType> {
    deserialize(data: string): NotifyType { return (<any>NotifyType)[data] }
    serialize(data: NotifyType): any { return data.toString() }
}

@JsonConverter
class EventStatusConverter implements JsonCustomConvert<EventStatus> {

    deserialize(data: string): EventStatus { return (<any>EventStatus)[data] }
    serialize(data: EventStatus): any { return data.toString() }

}

@JsonObject("Event")
export default class Event {
    @JsonProperty("id", Number, true)
    id?: number = undefined

    @JsonProperty("title", String, true)
    title?: string = undefined

    @JsonProperty("description", String, true)
    description?: string = undefined

    @JsonProperty("startsAt", DateTimeConverter, true)
    startsAt?: DateTime = undefined

    @JsonProperty("endsAt", DateTimeConverter, true)
    endsAt?: DateTime = undefined

    @JsonProperty("notifyFrom", NotifyTypeConverter, true)
    notifyFrom?: NotifyType = undefined

    @JsonProperty("potentialClients", [PotentialClient], true)
    potentialClients?: PotentialClient[] = undefined

    @JsonProperty("isAllDay", Boolean, true)
    isAllDay?: boolean = undefined

    @JsonProperty("notifyByMail", Boolean, true)
    notifyByMail?: boolean = undefined

    @JsonProperty("status", EventStatusConverter, true)
    status?: EventStatus = undefined
}